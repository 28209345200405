$text-color: #5e5873;
$blue: #8347e5;
$montserrat: "Montserrat", sans-serif;
.auth {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    label,
    p,
    span,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $text-color;
        font-family: $montserrat;
        font-weight: 500;
    }
    a {
        color: $blue;
        font-size: 13px;
    }
    label {
        font-size: 13px;
        margin-bottom: 0.25rem;
    }
    .form-group {
        margin-bottom: 1rem;
        position: relative;
    }
    p {
        font-size: 15px;
    }
    .btn-eye {
        position: absolute;
        right: 0;
        top: 3px;
        background-color: transparent;
        border: none;
        color: rgba(0, 0, 0, 0.25);
        &:hover,
        &:focus {
            background-color: transparent;
            color: rgba(0, 0, 0, 0.75);
        }
    }
    &.auth__form-left {
        .auth__image {
            order: 2;
        }
        .auth__form {
            order: 1;
        }
        .auth__logo-form {
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
        .auth__title {
            text-align: center;
            margin-bottom: 3rem;
        }
    }
}
.auth__logo-form {
    max-width: 300px;
}
.auth__image {
    flex: 1;
    background-color: #f8f8f8;
    height: 100%;
    position: relative;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
}
.auth__form {
    height: 100%;
    width: 33%;
    background-color: #fff;
    padding: 4rem;
    display: flex;
    align-items: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    form {
        width: 100%;
    }
}
.auth__title {
    font-size: 1.5rem;
    color: $text-color;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 2rem;
    text-transform: uppercase;
}
.btn-signin {
    background-color: $blue;
    font-family: $montserrat;
    font-weight: 600;
    color: #fff;
}
.auth__logo {
    position: absolute;
    max-width: 90px;
    $distance: 30px;
    &.top-left {
        left: $distance;
        top: $distance;
    }
    &.top-right {
        right: $distance;
        top: $distance;
    }
    &.bottom-left {
        left: $distance;
        bottom: $distance;
    }
    &.bottom-right {
        right: $distance;
        bottom: $distance;
    }
}
.auth__bg {
    object-fit: cover;
    height: 100%;
}
.auth__social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    a {
        $size: 34px;
        width: $size;
        height: $size;
        margin: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue;
        border-radius: 50%;
        color: #fff;
        transition: all 0.15s linear;
        &:hover {
            text-decoration: none;
            background-color: darken($blue, 15%);
        }
    }
}
@media (max-width: 1024px) {
    .auth__image {
        background-size: contain;
    }
    .auth__form {
        width: 45%;
    }
}
@media (max-width: 800px) {
    .auth__image {
        display: none;
    }
    .auth__form {
        padding-left: 2rem;
        padding-right: 2rem;
        min-height: 500px;
        overflow-y: auto;
        max-width: 100%;
        width: 100%;
    }
    .auth__logo-form {
        display: block;
    }
}
